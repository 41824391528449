<template>
    <v-app>
        <v-main>
            <!-- This is code for input valid check-->
            <v-container fluid>
                <v-row align>

                    <v-col>
                        <v-card class="mx-auto" max-width="400">
                            <v-card-title style="background-color: #273540">
                                <v-img class="mx-2" :src="require(`../assets/billinsight_logo.svg`)" max-height="150"
                                    max-width="150" contain>
                                </v-img>

                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text class="text--primary">
                                Une erreur est survenue lors du traitement de votre demande. Veuillez
                                réessayer ultérieurement. Nous nous excusons pour la gêne occasionnée.
                            </v-card-text>
                            <v-card-text class="text--primary">

                                Si le problème persiste, veuillez contacter notre service client à l'adresse
                                contact@billinsight.io

                            </v-card-text>
                        </v-card>
                    </v-col>

                </v-row>
            </v-container>
        </v-main>
        <v-footer :padless="true">
            <v-card flat tile width="100%" class="text-center" color="#273540" dark>

                <v-card-text class="white--text"><a href="/files/CGU_072023.pdf" download
                        style="color: #FFFFFF; text-decoration: none; font-size:12px;">CGU</a> |<a
                        href=" /files/MENTIONS_LEGALES_072023.pdf" download
                        style="color: #FFFFFF; text-decoration: none; font-size:12px;">Mentions Légales</a> |
                    <a href="https://azure.microsoft.com/fr-fr/explore/trusted-cloud/privacy/" target="_blank"
                        style="color: #FFFFFF; text-decoration: none; font-size:12px;">RGPD</a> — <strong
                        color-text="red">Billinsight</strong>
                </v-card-text>
                <v-divider></v-divider>
            </v-card>
        </v-footer>
    </v-app>
</template>

<script>


export default {
    name: 'UnauthorizedPage',
    methods: {},
    components: {},
    data: () => ({}),
}
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Open+Sans');

.v-application {
    font-family: "Open Sans";
}
</style>