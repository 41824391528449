<template>
    <v-app>
        <v-main>
            <!-- This is code for input valid check-->
            <v-container fluid>
                <v-row align>
                    <v-col></v-col>
                    <v-col>
                        <v-card class="mx-auto" striped max-width="800" :loading="true">
                            <template slot="progress">
                                <v-progress-linear indeterminate striped color="green"></v-progress-linear>
                            </template>
                            <v-card-title class="justify-center" style="background-color: #273540;">
                                <v-img class="mx-2" :src="require(`../assets/billinsight_logo.svg`)" max-height="150"
                                    max-width="150" contain>
                                </v-img>
                            </v-card-title>
                            <!--Client exists in ActiveDirectory and resources are being deployed.-->
                            <v-card-text>
                                <div class="my-4 text-subtitle-1">
                                    <v-icon color="success">mdi-cloud-upload</v-icon> Ressources en cours de
                                    déploiement.
                                </div>
                                <div>
                                    Vos
                                    ressources, actuellement en
                                    cours de déploiement, seront très prochainement disponibles.<br><br>
                                </div>
                                <div>Merci de ne pas quitter cette page ou de ne pas la rafraîchir.<br><br></div>
                                <div>
                                    Nous vous remercions pour votre confiance et votre patience.
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col></v-col>
                </v-row>
            </v-container>
        </v-main>
        <v-footer :padless="true">
            <v-card flat tile width="100%" class="text-center" color="#273540" dark>

                <v-card-text class="white--text"><a href="/files/CGU_072023.pdf" download
                        style="color: #FFFFFF; text-decoration: none; font-size:12px;">CGU</a> |<a
                        href=" /files/MENTIONS_LEGALES_072023.pdf" download
                        style="color: #FFFFFF; text-decoration: none; font-size:12px;">Mentions Légales</a> |
                    <a href="https://azure.microsoft.com/fr-fr/explore/trusted-cloud/privacy/" target="_blank"
                        style="color: #FFFFFF; text-decoration: none; font-size:12px;">RGPD</a> — <strong
                        color-text="red">Billinsight</strong>
                </v-card-text>
                <v-divider></v-divider>
            </v-card>
        </v-footer>
    </v-app>
</template>

<script>
import axios from 'axios';

export default {
    name: 'ResourcesDeployment',
    props: ["access_token", "session_token", "provider"],
    methods: {
        async refreshState() {
            axios.get("/api/GetConnectData", {
                params: { access_token: this.access_token, provider: this.provider }
            })
                .then(r1 => {
                    if (r1.data.status == "ready") {
                        clearInterval(this.schedulerRefreshState)
                        this.$router.push(
                            {
                                name: "HomePage|" + this.provider,
                                // don't push databases and contact_mail. Do not exist yet
                                params: {
                                    client: r1.data.client,
                                    company: r1.data.company,
                                    alias_mail: r1.data.alias_mail,
                                    session_token: this.session_token
                                }
                            })
                    } else {
                        // do nothing
                    }
                })
        },

        async setup() {

            await this.refreshState()
            this.schedulerRefreshState = setInterval(async () => await this.refreshState(), this.refreshFrequency)
        },
    },
    components: {},
    async created() {
        await this.setup()
    },
    data: () => ({ schedulerRefreshState: null, refreshFrequency: 10 * 1000 }),
}
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Open+Sans');

.v-application {
    font-family: "Open Sans";
}
</style>