import Vue from 'vue'
import Router from 'vue-router'
import HomePage from './../components/HomePage.vue'
import CallBack from './../components/CallBack.vue'
import Unauthorized from '../components/Unauthorized.vue'
import ServiceSetup from '../components/ServiceSetup.vue'
import ReadMe from '../components/ReadMe.vue'

Vue.use(Router)

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        // [START OF KOLIMMO DEFAULT IDENTITY PROVIDER]
        {
            // by default the idendity provider is Kolimmo.
            path: '/',
            name: "HomePage|kolimmo",
            beforeEnter(to, from, next) {
                // check for authentication
                if (from.path == "/auth/login/callback" || from.path == "/setup") {
                    next()
                } else {
                    // other case send to login.
                    next({ name: "Login" })
                }
            },
            component: HomePage,
            props: true
        },
        {
            path: "/auth/login",
            name: "Login",
            beforeEnter() {
                window.location.href = "https://app.kolimmo.com/oauth/authorize?client_id=xW_W3ESFKjVINYMzLe8p0QXcGYgEd4nwr0X0CK_mpjc&redirect_uri=https://billinsight.io/auth/login/callback&response_type=code&scope=connect";
            }
        },
        {
            path: "/auth/login/callback",
            name: "callback",
            component: CallBack,
            props: route => ({ code: route.query.code, provider: "kolimmo" })

        },
        // [END OF KOLIMMO DEFAULT IDENTITY PROVIDER]
        // [START OF MICROSOFT AZURE ACTIVE DIRECTORY IDENTITY PROVIDER]
        {
            path: '/aad',
            name: "HomePage|aad",
            beforeEnter(to, from, next) {
                // check for authentication
                if (from.path == "/auth/login/callback/aad" || from.path == "/setup") {
                    next()
                } else {
                    // other case send to login.
                    next({ name: "Login|aad" })
                }
            },
            component: HomePage,
            props: true
        },
        {
            path: "/.auth/login/aad",
            name: "Login|aad",
            beforeEnter() {
                window.location.href = "/.auth/login/aad?post_login_redirect_uri=https://billinsight.io/auth/login/callback/aad"
                //window.location.href = "/.auth/login/aad?post_login_redirect_uri=http://localhost:4280/auth/login/callback/aad"
            }
        },
        {
            path: "/auth/login/callback/aad",
            name: "callack|aad",
            component: CallBack,
            props: () => ({ code: "", provider: "aad" })
        },
        // [END OF MICROSOFT AZURE ACTIVE DIRECTORY IDENTITY PROVIDER]
        {
            path: "/unauthorized",
            name: "unauthorized",
            component: Unauthorized,

        },
        // RESOURCES BEING DEPLOYED
        {
            path: "/setup",
            name: "setup",
            // Check for source
            beforeEnter(to, from, next) {
                if (from.path.indexOf("/auth/login/callback") != -1) {
                    // Coming from callback
                    next()
                } else {
                    next({ name: "unauthorized" })
                }
            },

            component: ServiceSetup,
            props: true
        },
        // SERVICE DESCRIPTION
        {
            path: "/readme",
            name: "readme",
            component: ReadMe
        },
        // UNMAPPED ROUTES
        {
            path: '*',
            redirect: { name: "unauthorized" }
        }
    ]
})
