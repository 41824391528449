<template>
    <div></div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'CallBack',
    props: ["code", "provider"],
    methods: {
    },
    created() {
        // get authorization code from callback kolimmo
        axios.get("/api/GetToken", {
            params: { code: this.code, provider: this.provider }
        })
            .then(r0 => (
                // Set session_token to verify api calls
                localStorage.session_token = r0.data.session_token,
                // Get info about who's logged in
                axios.get("/api/GetConnectData", {
                    params: { access_token: r0.data.access_token, provider: this.provider }
                })
                    .then(r1 => {
                        if (r1.data.status == "ready") {
                            this.$router.push(
                                {
                                    name: "HomePage|" + this.provider,
                                    params: {
                                        client: r1.data.client,
                                        company: r1.data.company,
                                        alias_mail: r1.data.alias_mail,
                                        contact_mail: r1.data.contact_mail,
                                        databases: r1.data.databases,
                                        session_token: r0.data.session_token
                                    }
                                })
                        } else if (r1.data.status == "init") {
                            // Register new user resources
                            axios.get("/api/RegisterNewUserResources", {
                                params: {
                                    session_token: r0.data.session_token,
                                    client_unique_id: r1.data.client_unique_id,
                                    company: r1.data.company
                                }
                            })
                            this.$router.push({
                                name: "setup",
                                params: {
                                    access_token: r0.data.access_token,
                                    session_token: r0.data.session_token,
                                    provider: this.provider
                                }
                            })
                        } else {
                            // Resources are being deployed
                            this.$router.push({
                                name: "setup",
                                params: {
                                    access_token: r0.data.access_token,
                                    session_token: r0.data.session_token,
                                    provider: this.provider
                                }
                            })
                        }
                    }
                    )
                    .catch(error => {
                        console.log(error)
                        this.$router.push({ name: "unauthorized" })
                    })
            ))
            .catch(error => {
                console.log(error)
                this.$router.push({ name: "unauthorized" })
            })

    },
    data: () => ({})
}
</script>

<style></style>