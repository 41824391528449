<template>
    <v-app>
        <v-app-bar app color="#273540" dark>
            <v-img class="mx-2" :src="require(`../assets/billinsight_logo.svg`)" max-height="150" max-width="150"
                contain>
            </v-img>
            <v-spacer></v-spacer>
            <v-btn icon class="mr-0" @click="openStripeBillingPage">
                <v-icon color="orange darken-1">mdi-account-circle</v-icon>
            </v-btn>
            {{ this.company }}


            <template v-slot:extension>
                <v-tabs v-model="tab" align-with-title dense>
                    <v-tab href="#import">Import</v-tab>
                    <v-tab href="#suivi">
                        <v-badge v-if="Object.keys(jobsData).length > 0" color="orange darken-1" dot>
                            Suivi
                        </v-badge>
                        <span v-else>Suivi</span>
                    </v-tab>
                    <!-- v-btn on click openUserQueryNewModelDialog = True-->
                    <v-btn icon @click="openUserQueryNewModelDialog = true" :disabled="tab !== 'import'">
                        <v-icon>mdi-playlist-plus</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn icon href="/files/user_guide.pdf" download>
                        <v-icon>mdi-account-question</v-icon>
                    </v-btn>
                </v-tabs>
            </template>
        </v-app-bar>

        <v-main>
            <!-- This is code for input valid check-->
            <v-container fluid style="height:100%">
                <div class="tab-item-wrapper">
                    <v-tabs-items v-model="tab">
                        <v-tab-item :key="1" value="import">
                            <!-- This is code for the dialog : send batch and reset and model creation-->
                            <!-- alert -->
                            <v-snackbar v-model="successSendModel" :timeout="timeout_in_ms" centered top>
                                <v-icon color="success">mdi-check-circle</v-icon>
                                La requête de <strong>création de modèle</strong> a bien été envoyée.
                            </v-snackbar>
                            <v-snackbar v-model="errorModelUpload" :timeout="timeout_in_ms" centered top>
                                <v-icon color="error">mdi-alert-circle</v-icon> Une <strong>erreur</strong> s'est
                                produite lors de <strong>l'envoi de la requête de création du modèle</strong>. Veuillez
                                <strong>réessayer</strong>
                                svp.
                            </v-snackbar>
                            <v-snackbar v-model="errorModelAlreadyExists" :timeout="timeout_in_ms" centered top>
                                <v-icon color="error">mdi-alert-circle</v-icon> Le <strong>nom du fournisseur</strong>
                                renseigné existe déjà dans votre <strong>configuration client</strong>.
                                Veuillez <strong>réessayer</strong> avec un autre nom svp. Par exemple
                                <strong>engie</strong> <v-icon color="success">mdi-arrow-right-bold</v-icon>
                                <strong>engie-electricite</strong>.
                            </v-snackbar>

                            <v-snackbar v-model="infoJobsToValidate" :timeout="timeout_in_ms" centered top>
                                <v-icon color="info">mdi-information-outline</v-icon> Des <strong>Jobs</strong> sont
                                disponibles pour <strong>validation</strong> dans l'onglet <strong>Suivi</strong>.
                            </v-snackbar>
                            <v-snackbar v-model="infoNewJobsToValidate" :timeout="timeout_in_ms" centered top>
                                <v-icon color="info">mdi-information-outline</v-icon> De <strong>nouveaux Jobs</strong>
                                sont
                                disponibles pour <strong>validation</strong> dans l'onglet <strong>Suivi</strong>.
                            </v-snackbar>
                            <v-snackbar v-model="successSendJob" :timeout="timeout_in_ms" centered top>
                                <v-icon color="success">mdi-check-circle</v-icon> Votre <strong>Job</strong> a bien été
                                <strong>envoyé</strong>. Il est désormais en <strong>traitement</strong>.
                            </v-snackbar>
                            <v-snackbar v-model="errorEncrypted" :timeout="timeout_in_ms" centered top>
                                <v-icon color="error">mdi-alert-circle</v-icon> Certains documents sont
                                <strong>encryptés</strong>.
                                Veuillez les <strong>retirer</strong> et <strong>réessayer</strong> svp.
                            </v-snackbar>
                            <v-snackbar v-model="errorUpload" :timeout="timeout_in_ms" centered top>
                                <v-icon color="error">mdi-alert-circle</v-icon> Une <strong>erreur</strong> s'est
                                produite lors de <strong>l'envoi du job</strong>. Veuillez <strong>réessayer</strong>
                                svp.
                            </v-snackbar>
                            <v-snackbar v-model="errorDownload" :timeout="timeout_in_ms" centered top>
                                <v-icon color="error">mdi-alert-circle</v-icon> Une <strong>erreur</strong> s'est
                                produite lors du
                                <strong>téléchargement</strong>. Veuillez <strong>réessayer</strong> svp.
                            </v-snackbar>
                            <v-row justify="center">
                                <!-- dialog for model creation-->
                                <v-dialog v-model="openUserQueryNewModelDialog" persistent max-width="600px">
                                    <v-overlay :absolute="false" :value="enableOverlayImportMain">
                                        <v-progress-circular indeterminate size="64"></v-progress-circular>
                                    </v-overlay>
                                    <v-card>
                                        <v-card-title class="text-h5">
                                            Requête de création de modèle
                                        </v-card-title>
                                        <ValidationObserver ref="observer" v-slot="{ invalid }">
                                            <v-card-text>
                                                <v-form>
                                                    <ValidationProvider name="Nom du fournisseur"
                                                        rules="required|min_max_length|pattern" v-slot="{ errors }">
                                                        <v-text-field v-model="userQueryNewModelName"
                                                            label="Nom du fournisseur"
                                                            placeholder="Le nom du fournisseur doit contenir entre 3 et 25 caractères en minuscules"
                                                            :error-messages="errors" required />
                                                    </ValidationProvider>

                                                    <!-- Similarly add ValidationProvider for other fields with respective rules -->
                                                    <ValidationProvider name="Fichiers"
                                                        rules="required|file_size|file_count" v-slot="{ errors }">
                                                        <v-file-input v-model="userQueryNewModelFiles" show-size chips
                                                            small-chips multiple color="primary"
                                                            label="Factures d'exemple (min. 3 immeubles distincts)"
                                                            placeholder="Choisissez vos fichiers .pdf"
                                                            :error-messages="errors">
                                                            <template v-slot:selection="{ index, text }">
                                                                <v-chip :key="index" color="primary" text-color="white"
                                                                    small>
                                                                    {{ text }}
                                                                </v-chip>
                                                            </template>
                                                        </v-file-input>
                                                    </ValidationProvider>

                                                    <!-- Here's an example for userQueryNewModelContractNumber -->
                                                    <ValidationProvider name="Numéro de contrat" rules="required"
                                                        v-slot="{ errors }">
                                                        <v-text-field v-model="userQueryNewModelContractNumber"
                                                            label="Numéro de contrat"
                                                            placeholder="Par ex: 'Numéro de ligne fixe (p1 facture). Par exemple 0123456789'"
                                                            :error-messages="errors" required />
                                                    </ValidationProvider>

                                                    <!-- userQueryNewModelInvoiceLabel -->
                                                    <ValidationProvider name="Libellé de facture" rules="required"
                                                        v-slot="{ errors }">
                                                        <v-text-field v-model="userQueryNewModelInvoiceLabel"
                                                            label="Libellé de facture"
                                                            placeholder="Par ex: 'ORANGE - 0123456789'"
                                                            :error-messages="errors" required />
                                                    </ValidationProvider>

                                                </v-form>
                                            </v-card-text>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn color="red darken-1" text @click="resetUserQueryNewModel">
                                                    Reset
                                                </v-btn>
                                                <v-btn color="green darken-1" text @click="processUserQueryNewModel"
                                                    :disabled="invalid">
                                                    Envoi
                                                </v-btn>
                                            </v-card-actions>
                                        </ValidationObserver>
                                    </v-card>
                                </v-dialog>
                                <!-- dialog for send batch -->
                                <v-overlay :absolute="false" :value="enableOverlayImportMain">
                                    <v-progress-circular indeterminate size="64"></v-progress-circular>
                                </v-overlay>
                                <v-dialog v-model="openSendBatchConfirmDialog" persistent max-width="500">
                                    <v-card>
                                        <v-card-title class="text-h5">
                                            Envoi du job
                                        </v-card-title>
                                        <v-card-text>Veuillez confirmer l'<strong>envoi</strong> du job de factures.
                                            Une
                                            fois
                                            validée cette action est <strong>irréversible</strong>.
                                        </v-card-text>
                                        <!-- if batch resolver then remove check on alertIntervals-->
                                        <v-card-text
                                            v-if="this.alertIntervals.length > 0 && this.batchResolver == false">
                                            <div class="font-weight-bold">
                                                <v-icon small color="orange darken-1">mdi-alert-outline</v-icon>
                                                Attention
                                            </div>

                                            <v-timeline dense align-top>
                                                <v-timeline-item v-for="(  item, index  ) in alertIntervals  "
                                                    :key="index" color="orange darken-1" small>
                                                    <div><strong>Section n° {{ item.section }}</strong> | <em>Une
                                                            facture
                                                            s'étend
                                                            de p.{{ item.start }} à p.{{ item.end }}</em></div>
                                                </v-timeline-item>
                                            </v-timeline>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="red darken-1" text
                                                @click="openSendBatchConfirmDialog = false">
                                                Non
                                            </v-btn>
                                            <v-btn color="green darken-1" text @click="sendBatchPostConfirm()">
                                                Oui
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-row>
                            <!-- dialog for reset batch -->
                            <v-row justify="center">
                                <v-dialog v-model="openResetBatchConfirmDialog" persistent max-width="500">
                                    <v-card>
                                        <v-card-title class="text-h5">
                                            Reset du module d'import
                                        </v-card-title>
                                        <v-card-text>Veuillez confirmer l'opération de <strong>reset</strong>. Une
                                            fois
                                            validée cette
                                            action est
                                            <strong>irréversible</strong>.
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="red darken-1" text
                                                @click="openResetBatchConfirmDialog = false">
                                                Non
                                            </v-btn>
                                            <v-btn color="green darken-1" text @click="resetVariables()">
                                                Oui
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-row>

                            <!-- This is the main code for the app body-->
                            <v-row>
                                <v-col md="5">
                                    <v-row>
                                        <v-col>
                                            <v-card>
                                                <v-card-title>
                                                    <v-row>
                                                        <v-col>
                                                            <div>
                                                                <v-icon color="success">mdi-cloud-check</v-icon>
                                                                Module d'import
                                                            </div>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-title>
                                                <v-card-subtitle>
                                                    Configurez vos jobs de factures
                                                </v-card-subtitle>
                                                <v-card-text>
                                                    <v-select v-if="has_multiple_datasources"
                                                        prepend-icon="mdi-database-import" :items="multiple_datasources"
                                                        label="Database" dense v-model="selected_datasource"
                                                        style="width: 50%;">
                                                    </v-select>

                                                    <v-row>
                                                        <!--v-col>
                                                        <div>Fichiers supportés application/pdf.
                                                        </div>
                                                    </v-col-->
                                                        <v-col class="text-right">
                                                            Envoi
                                                            <v-btn icon color="green"
                                                                v-on:click="openDialogSendBatchConfirm()"
                                                                :disabled="this.disabled || (this.has_multiple_datasources && this.selected_datasource === undefined)">
                                                                <v-icon>mdi-send</v-icon>
                                                            </v-btn>
                                                            <v-divider>vertical</v-divider>
                                                            Reset
                                                            <v-btn icon color="red"
                                                                v-on:click="openDialogResetBatchConfirm()"
                                                                :disabled="this.files.length == 0">
                                                                <v-icon>mdi-delete-forever</v-icon>
                                                            </v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                                <v-card-text>
                                                    <v-row>
                                                        <v-btn-toggle v-model="toggle_source" mandatory color="primary">
                                                            <v-btn small v-on:click="resetVariables">
                                                                <v-icon v-if="toggle_source == 0" color="primary"
                                                                    small>mdi-microsoft-windows</v-icon>
                                                                <v-icon v-else small>mdi-microsoft-windows</v-icon>
                                                            </v-btn>

                                                            <v-tooltip right>
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-btn small v-on:click="resetVariables" v-on="on"
                                                                        v-bind="attrs">
                                                                        <v-badge v-if="Object.keys(mails).length > 0"
                                                                            color="orange darken-1" dot>
                                                                            <v-icon v-if="toggle_source == 1"
                                                                                color="primary" small>mdi-email</v-icon>
                                                                            <v-icon v-else small>mdi-email</v-icon>
                                                                        </v-badge>
                                                                        <v-icon v-else small>mdi-email</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>
                                                                    <v-icon small>mdi-account-box</v-icon>
                                                                    {{ this.alias_mail }}
                                                                </span>
                                                            </v-tooltip>


                                                        </v-btn-toggle>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col v-if="(toggle_source == 0)">
                                                            <v-form ref="form">
                                                                <v-file-input v-model="files" multiple show-size dense
                                                                    :clearable="false" prepend-icon="mdi-file-pdf-box"
                                                                    label="Sélectionnez ici vos factures."
                                                                    v-on:change="ProcessIncomingPDFFiles()"
                                                                    :rules="fileInputValidationRules"
                                                                    accept="application/pdf"
                                                                    :disabled="this.selectionLock">
                                                                    >
                                                                </v-file-input>
                                                            </v-form>
                                                        </v-col>
                                                        <v-col v-else>
                                                            <v-select v-model="activeMailsIDs" :items="mails"
                                                                item-value="value" :item-text="displayMail" dense
                                                                :clearable="false" ref="ref_select_mail" multiple
                                                                :label="'Sélectionnez ici vos mails.'"
                                                                :no-data-text="'Aucun mail disponible.'"
                                                                prepend-icon="mdi-email-check" @change="onSelectChange"
                                                                :disabled="this.selectionLock" :item-key="'value'">
                                                                <template v-slot:selection="{ item, index }">

                                                                    <v-chip v-if="index === 0" small color="primary"
                                                                        text-color="white">{{
                                                                            item.dt_local
                                                                        }}</v-chip>
                                                                    <span v-if="index === 1"
                                                                        class="grey--text text-caption">
                                                                        (+{{ activeMailsIDs.length - 1 }} others)
                                                                    </span>
                                                                </template>

                                                                <template v-slot:prepend-item>
                                                                    <v-list-item class="sticky">

                                                                        <v-row class="pa-0">

                                                                            <v-col cols="12"
                                                                                class="pa-0 d-flex justify-end"><v-btn
                                                                                    icon @click="toggle" color="primary"
                                                                                    dense>
                                                                                    <v-icon
                                                                                        :color="activeMailsIDs.length > 0 ? 'indigo darken-4' : ''">
                                                                                        {{ toggleIcon() }}
                                                                                    </v-icon></v-btn>
                                                                                <v-btn
                                                                                    @click="downloadIncomingMailDocuments"
                                                                                    color="green" icon dense
                                                                                    :disabled="activeMailsIDs.length == 0">
                                                                                    <v-icon>mdi-send</v-icon>
                                                                                </v-btn>

                                                                            </v-col>

                                                                        </v-row>
                                                                    </v-list-item>

                                                                </template>

                                                                <!--template slot="item" slot-scope="data">
                                                                    <v-divider class="mb-2"></v-divider>
                                                                    <v-list-item>

                                                                        <v-list-item-avatar>
                                                                            <v-icon>
                                                                                mdi-timeline-clock-outline
                                                                            </v-icon>
                                                                        </v-list-item-avatar>
                                                                        <v-list-item-content>
                                                                            <span
                                                                                v-if="(data.item.subject == null)">Aucun
                                                                                objet</span>
                                                                            <span v-else>{{ data.item.subject }}</span>
                                                                            <v-list-item-title>{{ data.item.dt_local }}
                                                                            </v-list-item-title>
                                                                        </v-list-item-content>
                                                                    </v-list-item>
                                                                </template-->
                                                            </v-select>

                                                        </v-col>
                                                        <v-divider vertical></v-divider>
                                                        <v-col>
                                                            <v-btn v-if="batchResolver == true" small color="primary"
                                                                class="ma-2 white--text"
                                                                :disabled="this.files.length == 0"
                                                                v-on:click="handleBatchContextSwitcher()">
                                                                Batch (on)
                                                                <v-icon small>
                                                                    mdi-flash
                                                                </v-icon>
                                                            </v-btn>
                                                            <v-btn v-else small color="grey" class="ma-2 white--text"
                                                                :disabled="this.files.length == 0"
                                                                v-on:click="handleBatchContextSwitcher()">
                                                                Batch (off)
                                                                <v-icon small>
                                                                    mdi-flash-off
                                                                </v-icon>
                                                            </v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                                <v-divider></v-divider>
                                                <v-card-text v-if="this.filesClusters.length == 0">
                                                    <v-row>
                                                        <v-col>
                                                            <v-skeleton-loader boilerplate
                                                                type="heading, list-item, divider, list-item, divider, list-item, divider, list-item, divider, list-item, divider, list-item"
                                                                :height="420">
                                                            </v-skeleton-loader>
                                                        </v-col>
                                                        <v-col>
                                                            <v-skeleton-loader boilerplate
                                                                type="heading, list-item, divider, list-item, divider, list-item, divider, list-item, divider, list-item, divider, list-item"
                                                                :height="420">
                                                            </v-skeleton-loader>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                                <v-card-text v-else>
                                                    <v-row>
                                                        <v-col>
                                                            Sections
                                                            <v-divider></v-divider>
                                                        </v-col>
                                                        <v-col align="right">
                                                            <v-chip small class="ma-2" color="primary">
                                                                Section n°{{ selectedSectionIndex + 1 }}
                                                            </v-chip>
                                                        </v-col>
                                                        <v-col>
                                                            Fournisseurs
                                                            <v-divider></v-divider>
                                                        </v-col>
                                                        <v-col align="right">
                                                            <v-chip small class="ma-2" color="primary">
                                                                {{ selectedSupplier.model_id === '__auto__' ? 'Auto'
                                                                    :
                                                                    selectedSupplier.alias }}

                                                            </v-chip>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col>
                                                            <v-list dense class="overflow-y-auto" height="327">
                                                                <v-list-item-group mandatory
                                                                    v-model="selectedSectionIndex" color="primary">
                                                                    <template>
                                                                        <div v-for="(  item, index  ) in filesClusters  "
                                                                            :key="index">
                                                                            <v-divider></v-divider>
                                                                            <v-list-item
                                                                                v-on:click="handlePreviewImportFiles(index)">
                                                                                <v-list-item-action>
                                                                                    <v-icon :id="`Icon-${index}`">
                                                                                        mdi-segment
                                                                                    </v-icon>
                                                                                </v-list-item-action>

                                                                                <v-list-item-content>
                                                                                    <v-list-item-title>
                                                                                        Section {{ index + 1 }}
                                                                                        /
                                                                                        {{ filesClusters.length }}
                                                                                        |
                                                                                        <em>
                                                                                            p.
                                                                                            {{ index * chunckSize +
                                                                                                1 }}
                                                                                            -
                                                                                            {{ index * chunckSize
                                                                                                + item.length
                                                                                            }}
                                                                                        </em>
                                                                                    </v-list-item-title>
                                                                                </v-list-item-content>
                                                                            </v-list-item>
                                                                        </div>
                                                                    </template>
                                                                </v-list-item-group>
                                                            </v-list>
                                                        </v-col>
                                                        <v-divider vertical></v-divider>
                                                        <v-col>
                                                            <v-text-field dense prepend-icon="mdi-card-search"
                                                                v-model.trim="searchModel" label="Recherche..."
                                                                clearable>
                                                            </v-text-field>
                                                            <v-list dense class="overflow-y-auto" height="327">
                                                                <v-list-item-group mandatory v-model="selectedSupplier"
                                                                    color="primary">
                                                                    <v-list-item v-for="  item in filteredSuppliers  "
                                                                        :key="item.model_id" :value="item">
                                                                        <v-list-item-action>
                                                                            <v-icon>{{ item.model_id === '__auto__' ?
                                                                                'mdi-brightness-auto' :
                                                                                'mdi-google-podcast'
                                                                                }}</v-icon>
                                                                        </v-list-item-action>

                                                                        <v-list-item-content>
                                                                            <v-list-item-title>
                                                                                {{ item.model_id === '__auto__' ? 'Auto'
                                                                                    :
                                                                                    item.alias }}
                                                                            </v-list-item-title>
                                                                        </v-list-item-content>
                                                                    </v-list-item>

                                                                </v-list-item-group>
                                                            </v-list>

                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <!--div> Column 2 <div-->
                                <v-col md="7">

                                    <v-card v-if="this.filesImportPreview.length > 0" class="card-filepond0">
                                        <v-overlay :absolute="true" :value="enableOverlayImportPond">
                                            <v-progress-circular indeterminate size="64"></v-progress-circular>
                                        </v-overlay>
                                        <file-pond id="filepond0" allowBrowse="false" allowMultiple="false"
                                            allowDrop="false" allowPaste="false" allowReplace="false"
                                            allowRevert="false" allowProcess="false" allowReorder="false"
                                            allowRemove="false" dropOnElement="false" label-idle=""
                                            accepted-file-types="application/pdf" v-bind:files="filesImportPreview"
                                            server="/" credits="false" class="filepond" instantUpload="false"
                                            :max-files="chunckSize + maxInterval" maxFileSize="10MB"
                                            maxParallelUploads="1" :pdfPreviewHeight="filepond0_height"
                                            v-on:activatefile="handleFileState" v-on:addfile="handleFileAdd" />
                                    </v-card>
                                    <v-card v-else class="skeleton-card-filepond">
                                        <v-overlay :absolute="true" :value="enableOverlayImportPond">
                                            <v-progress-circular indeterminate size="64"></v-progress-circular>
                                        </v-overlay>
                                        <object width="100%" height="30%">
                                        </object>
                                        <!--Content before waves-->
                                        <v-card-text align="center" style="padding:0">
                                            <v-img class="mx-2" :src="require(`../assets/billinsight_logo.svg`)"
                                                max-height="150" max-width="150" contain>
                                            </v-img>
                                        </v-card-text>

                                        <!--Waves Container-->
                                        <div>
                                            <svg class="waves" xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28"
                                                preserveAspectRatio="none" shape-rendering="auto">
                                                <defs>
                                                    <path id="gentle-wave"
                                                        d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                                                </defs>
                                                <g class="parallax">
                                                    <use xlink:href="#gentle-wave" x="48" y="0"
                                                        fill="rgba(255,255,255,0.7)" />
                                                    <use xlink:href="#gentle-wave" x="48" y="3"
                                                        fill="rgba(255,255,255,0.5)" />
                                                    <use xlink:href="#gentle-wave" x="48" y="5"
                                                        fill="rgba(255,255,255,0.3)" />
                                                    <use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" />
                                                </g>
                                            </svg>
                                            <object width="100%" height="30%">
                                            </object>
                                        </div>
                                        <!--v-skeleton-loader type="image@5">
                                    </v-skeleton-loader-->
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-tab-item>
                        <!-- SECOND TAB -->
                        <v-tab-item :key="2" value="suivi">
                            <v-snackbar v-model="successSendResults" :timeout="timeout_in_ms" centered top>
                                <v-icon color="success">mdi-check-circle</v-icon> Vos <strong>résultats</strong> ont été
                                <strong>validés</strong> avec succès.
                            </v-snackbar>
                            <v-snackbar v-model="successDropResults" :timeout="timeout_in_ms" centered top>
                                <v-icon color="success">mdi-check-circle</v-icon> Vos <strong>résultats</strong> ont été
                                <strong>supprimés</strong> avec succès.
                            </v-snackbar>
                            <v-snackbar v-model="errorDownload" :timeout="timeout_in_ms" centered top>
                                <v-icon color="error">mdi-alert-circle</v-icon> Une <strong>erreur</strong> s'est
                                produite lors du
                                <strong>téléchargement</strong>. Veuillez <strong>réessayer</strong> svp.
                            </v-snackbar>
                            <v-row justify="center">
                                <v-overlay :absolute="false" :value="enableOverlaySuiviMain">
                                    <v-progress-circular indeterminate size="64">
                                    </v-progress-circular>
                                </v-overlay>
                                <v-col>
                                    <v-row>
                                        <v-col>
                                            <v-card>
                                                <v-card-title>
                                                    <v-row>
                                                        <v-col>
                                                            <div>
                                                                <v-icon color="success">mdi-cloud-check</v-icon>
                                                                Module de suivi
                                                            </div>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-title>
                                                <v-card-subtitle>
                                                    Validez et envoyez vos résultats
                                                </v-card-subtitle>
                                                <v-card-text>
                                                    <v-row>
                                                        <v-col md="3">
                                                            Jobs
                                                            <v-divider>vertical</v-divider>
                                                        </v-col>

                                                        <v-col md="9">
                                                            Détails
                                                            <v-divider>vertical</v-divider>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row v-if="hierarchy.length > 0">
                                                        <v-col md="3">
                                                            <!-- :open.sync="open"-->
                                                            <v-treeview :active.sync="activeBatchId" :items="hierarchy"
                                                                activatable color="warning" open-on-click transition
                                                                @update:active="panel = []; filesSuiviPreview = []; getActiveJob()">
                                                                <template v-slot:prepend="{ item }">
                                                                    <v-icon small v-if="!item.children">
                                                                        mdi-clock-outline
                                                                    </v-icon>
                                                                </template>
                                                            </v-treeview>

                                                        </v-col>
                                                        <v-divider vertical></v-divider>
                                                        <v-col md="9" v-if="Object.keys(activeJob).length > 0">
                                                            <v-card>
                                                                <v-card-title>
                                                                    <v-col>
                                                                        Job
                                                                        <v-chip small class="ma-2" color="blue"
                                                                            text-color="white">
                                                                            <v-avatar small left>
                                                                                <v-icon small>
                                                                                    mdi-clock-outline
                                                                                </v-icon>
                                                                            </v-avatar>
                                                                            {{ activeJob.dt_local }}
                                                                        </v-chip>
                                                                    </v-col>
                                                                </v-card-title>
                                                                <v-card-subtitle>

                                                                    <div>Recharger<v-btn icon color="primary"
                                                                            v-on:click="cleanCacheOperations('job')">
                                                                            <v-icon>mdi-restore </v-icon>
                                                                        </v-btn>
                                                                    </div>
                                                                    <v-row>
                                                                        <v-col class="text-right">
                                                                            Envoi
                                                                            <v-btn icon color="green"
                                                                                v-on:click="sendJob">
                                                                                <v-icon>mdi-send</v-icon>
                                                                            </v-btn>
                                                                            |
                                                                            Suppr.
                                                                            <v-btn icon color="red"
                                                                                v-on:click="removeJob">
                                                                                <v-icon>mdi-delete-forever</v-icon>
                                                                            </v-btn>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-card-subtitle>
                                                                <v-expansion-panels v-model="panel">
                                                                    <v-expansion-panel
                                                                        v-for="(  data, key  ) in activeJob.data  "
                                                                        :key="key" @click="filesSuiviPreview = []">
                                                                        <v-expansion-panel-header>
                                                                            <div>
                                                                                <v-chip color="primary"
                                                                                    class="ml-0 mr-2 white--text" label
                                                                                    small>
                                                                                    {{ data.length }}
                                                                                </v-chip>
                                                                                <span>
                                                                                    {{ getModelAlias(key) }}
                                                                                </span>
                                                                            </div>
                                                                            <!--div align="right">
                                                                                <v-chip small class="ma-2" color="red"
                                                                                    text-color="white">
                                                                                    21
                                                                                    <v-icon right>
                                                                                        mdi-close
                                                                                    </v-icon>
                                                                                </v-chip>
                                                                            </div-->
                                                                        </v-expansion-panel-header>
                                                                        <v-expansion-panel-content>
                                                                            <v-col align="left">
                                                                                Recharger
                                                                                <v-btn icon color="primary"
                                                                                    v-on:click="cleanCacheOperations('supplier', key)">
                                                                                    <v-icon>mdi-restore
                                                                                    </v-icon>
                                                                                </v-btn>
                                                                            </v-col>
                                                                            <template>
                                                                                <v-data-table dense :headers="headers"
                                                                                    :items="data"
                                                                                    :sort-by="['CONFIDENCE']"
                                                                                    :sort-desc="[false]"
                                                                                    :footer-props="{ itemsPerPageOptions: [5, 10, 20] }"
                                                                                    item-key="name" class="elevation-1">

                                                                                    <!-- modify templates for headers -->
                                                                                    <template v-slot:[`header.DELETE`]>
                                                                                        <v-icon color="red" small>
                                                                                            mdi-delete-forever
                                                                                        </v-icon>
                                                                                    </template>
                                                                                    <template v-slot:[`header.preview`]>
                                                                                        <v-icon color="green" small>
                                                                                            mdi-file-find
                                                                                        </v-icon>
                                                                                    </template>
                                                                                    <!-- modify templates for rows -->
                                                                                    <template
                                                                                        v-slot:[`item.DATE_FAC`]="{ item }">
                                                                                        {{
                                                                                            convertDateHelper(item.DATE_FAC)
                                                                                        }}
                                                                                    </template>

                                                                                    <template
                                                                                        v-slot:[`item.LIBELLE_FAC`]="{ item }">
                                                                                        <v-tooltip right>
                                                                                            <template
                                                                                                v-slot:activator="{ on, attrs }">
                                                                                                <span v-bind="attrs"
                                                                                                    v-on="on">...</span>
                                                                                            </template>
                                                                                            <span>{{
                                                                                                item.LIBELLE_FAC
                                                                                            }}</span>
                                                                                        </v-tooltip>
                                                                                    </template>

                                                                                    <template
                                                                                        v-slot:[`item.DELETE`]="{ item }">
                                                                                        <v-simple-checkbox
                                                                                            v-model="item.DELETE"
                                                                                            v-on:click="cacheOperation(item.DOCUMENT_ID, key)">
                                                                                        </v-simple-checkbox>
                                                                                    </template>
                                                                                    <template
                                                                                        v-slot:[`item.preview`]="{ item }">
                                                                                        <v-icon small class="mr-2"
                                                                                            @click="downloadPreviewDocument(item.DOCUMENT_ID)">
                                                                                            mdi-file-find
                                                                                        </v-icon>
                                                                                    </template>
                                                                                    <template
                                                                                        v-slot:[`item.CONFIDENCE`]="{ item }">
                                                                                        {{
                                                                                            parseInt(100 *
                                                                                                item.CONFIDENCE)
                                                                                        }}%
                                                                                    </template>

                                                                                    <template
                                                                                        v-slot:[`item.MONTANT_TTC`]="{ item }">
                                                                                        <strong>{{
                                                                                            item.MONTANT_TTC
                                                                                            }}
                                                                                        </strong>
                                                                                    </template>


                                                                                </v-data-table>
                                                                            </template>
                                                                        </v-expansion-panel-content>
                                                                    </v-expansion-panel>
                                                                </v-expansion-panels>
                                                            </v-card>
                                                        </v-col>
                                                        <v-col md="9" v-else>
                                                            <v-skeleton-loader class="mx-auto" boilerplate
                                                                type="heading, list-item, actions, image" :height="250">
                                                            </v-skeleton-loader>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row v-else>
                                                        <v-col md="3">
                                                            <v-skeleton-loader boilerplate
                                                                type="heading, list-item, divider, list-item, heading, list-item , divider, list-item"
                                                                :height="250">
                                                            </v-skeleton-loader>
                                                        </v-col>
                                                        <v-divider vertical inset></v-divider>
                                                        <v-col md="9">
                                                            <v-skeleton-loader class="mx-auto" boilerplate
                                                                type="heading, list-item, actions, image" :height="250">
                                                            </v-skeleton-loader>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-col>

                                <v-col md="5">
                                    <v-row>
                                        <v-col>
                                            <v-card v-if="this.filesSuiviPreview.length > 0" class="card-filepond1">
                                                <v-overlay :absolute="true" :value="enableOverlaySuiviPond">
                                                    <v-progress-circular indeterminate size="64">
                                                    </v-progress-circular>
                                                </v-overlay>
                                                <file-pond id="filepond1" allowBrowse="false" allowMultiple="false"
                                                    allowDrop="false" allowPaste="false" allowReplace="false"
                                                    allowRevert="false" allowProcess="false" allowReorder="false"
                                                    allowRemove="false" dropOnElement="false" label-idle=""
                                                    accepted-file-types="application/pdf"
                                                    v-bind:files="filesSuiviPreview" server="/" credits="false"
                                                    class="filepond" instantUpload="false" :max-files="maxInterval"
                                                    maxFileSize="10MB" maxParallelUploads="1" pdfPreviewHeight="800" />
                                            </v-card>
                                            <v-card v-else class="skeleton-card-filepond">
                                                <v-overlay :absolute="true" :value="enableOverlaySuiviPond">
                                                    <v-progress-circular indeterminate size="64">
                                                    </v-progress-circular>
                                                </v-overlay>
                                                <object width="100%" height="30%">
                                                </object>
                                                <!--Content before waves-->
                                                <v-card-text align="center" style="padding:0">
                                                    <v-img class="mx-2" :src="require(`../assets/billinsight_logo.svg`)"
                                                        max-height="150" max-width="150" contain>
                                                    </v-img>
                                                </v-card-text>

                                                <!--Waves Container-->
                                                <div>
                                                    <svg class="waves" xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28"
                                                        preserveAspectRatio="none" shape-rendering="auto">
                                                        <defs>
                                                            <path id="gentle-wave"
                                                                d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                                                        </defs>
                                                        <g class="parallax">
                                                            <use xlink:href="#gentle-wave" x="48" y="0"
                                                                fill="rgba(255,255,255,0.7)" />
                                                            <use xlink:href="#gentle-wave" x="48" y="3"
                                                                fill="rgba(255,255,255,0.5)" />
                                                            <use xlink:href="#gentle-wave" x="48" y="5"
                                                                fill="rgba(255,255,255,0.3)" />
                                                            <use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" />
                                                        </g>
                                                    </svg>
                                                    <object width="100%" height="30%">
                                                    </object>
                                                </div>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                        </v-tab-item>
                    </v-tabs-items>
                </div>
            </v-container>
        </v-main>
        <v-footer :padless="true">
            <v-card flat tile width="100%" class="text-center" color="#273540" dark>

                <v-card-text class="white--text"><a href="/files/CGU_072023.pdf" download
                        style="color: #FFFFFF; text-decoration: none; font-size:12px;">CGU</a> |<a
                        href=" /files/MENTIONS_LEGALES_072023.pdf" download
                        style="color: #FFFFFF; text-decoration: none; font-size:12px;">Mentions Légales</a> |
                    <a href="https://azure.microsoft.com/fr-fr/explore/trusted-cloud/privacy/" target="_blank"
                        style="color: #FFFFFF; text-decoration: none; font-size:12px;">RGPD</a> — <strong
                        color-text="red">Billinsight</strong>
                </v-card-text>
                <v-divider></v-divider>
            </v-card>
        </v-footer>
    </v-app>
</template>

<script>
// Import axios
import axios from 'axios';
// Import FilePond component 
import vueFilePond, { setOptions } from "vue-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
// Import FilePond plugins
// Import image preview plugin styles
import FilePondPluginPdfPreview from "filepond-plugin-pdf-preview";
import "filepond-plugin-pdf-preview/dist/filepond-plugin-pdf-preview.css";
// import uuid for unique identification
import { uuid } from 'vue-uuid';
// PDF Document instance
const PDFDocument = require('pdf-lib').PDFDocument;

// Create component
const FilePond = vueFilePond(FilePondPluginPdfPreview);
setOptions({
    allowPdfPreview: true,
    pdfComponentExtraParams: 'toolbar=0&view=fit&page=1'
});


// Vee-validate
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from 'vee-validate/dist/rules';

// Add the rules
extend('required', {
    ...required,
    message: 'Ce champ est obligatoire'
})

extend('min_max_length', {
    validate: (value) => value.length >= 3 && value.length <= 25,
    message: 'Le nom du modèle doit contenir entre 3 et 25 caractères'
})
extend('pattern', {
    validate: (value) => /^(?!-)[a-z0-9-]*(?<!-)$/.test(value),
    message: 'Le nom du modèle ne peut contenir que des lettres minuscules, des chiffres et des tirets, commencer et finir par une lettre ou un chiffre, et ne pas avoir de tirets consécutifs'
})

extend('file_size', {
    validate: (files) => {
        const totalSize = files.reduce((total, file) => total + file.size, 0)
        // Maximum size in bytes
        const maxSize = 20 * 1024 * 1024
        return totalSize <= maxSize
    },
    message: 'La taille totale des fichiers doit être inférieure à 20 Mo'
})

extend('file_count', {
    validate: (files) => files.length >= 5 && files.length <= 10,
    message: 'Vous devez sélectionner entre 5 et 10 fichiers .pdf'
})


export default {
    name: 'App',
    props: [
        "client", "company",
        "alias_mail", "contact_mail",
        "databases", "session_token"
    ],

    methods: {

        displayMail(item) {
            // Assuming dt_local is a valid date string parseable by Date
            const date = new Date(item.dt_local);
            // Format date to "yyyy-mm-dd hh:mm"
            const formattedDate = date.toISOString().slice(0, 16).replace('T', ' ');
            return `${item.subject} (${formattedDate})`; // Concatenates the subject and formatted date
        },


        // Override select change
        onSelectChange() {
            setTimeout(() => {
                const index = this.$refs.ref_select_mail.$refs.menu.listIndex;
                this.$refs.ref_select_mail.$refs.menu.listIndex = index + 1;
            }, 10);
        },

        // openStrip page
        openStripeBillingPage() {
            const url = "https://billing.stripe.com/p/login/cN24ht9xFbtF9Py144?prefilled_email=" + this.contact_mail
            window.open(url, "_blank");
        },

        // reset userQueryNewModel & handle form submit
        resetUserQueryNewModel() {
            this.userQueryNewModelName = ''
            this.userQueryNewModelFiles = []
            this.userQueryNewModelContractNumber = ''
            this.userQueryNewModelInvoiceLabel = ''
            this.$refs.observer.reset()
            this.openUserQueryNewModelDialog = false
        },

        // register new model async RgisterNewModelCreation
        async processUserQueryNewModel() {
            // check if model already exists in this suppliers
            if (this.suppliers.map(item => item.model_id).indexOf(this.userQueryNewModelName) >= 0) {
                this.errorModelAlreadyExists = true
                return
            }

            // overlay loading
            this.enableOverlayImportMain = true
            // Create a new FormData instance
            var formData = new FormData();
            // Append each file to the form data
            for (var i = 0; i < this.userQueryNewModelFiles.length; i++) {
                let file = this.userQueryNewModelFiles[i];
                formData.append("files", file);
            }
            // Append the rest of the data to the form data
            formData.append("session_token", this.session_token);
            formData.append("client", this.client);
            formData.append("model_name", this.userQueryNewModelName);
            formData.append("contract_number", this.userQueryNewModelContractNumber);
            formData.append("invoice_label", this.userQueryNewModelInvoiceLabel);

            // Make the POST request
            try {
                const res = await axios.post("/api/RegisterNewModelCreation", formData)
                if (res.status === 200) {
                    this.resetUserQueryNewModel()
                    this.openUserQueryNewModelDialog = false
                    this.successSendModel = true
                } else {
                    // handle failure scenario here
                    this.resetUserQueryNewModel()
                    this.openUserQueryNewModelDialog = false
                    this.errorModelUpload = true
                }
            } catch (error) {
                this.resetUserQueryNewModel()
                this.openUserQueryNewModelDialog = false
                this.errorModelUpload = true
            } finally
            // remove overlay loading
            {
                this.enableOverlayImportMain = false
            }
        },


        async getUserConfig() {
            // GetUserConfig (version async/await no callback)
            try {
                const res = await axios.get("/api/GetUserConfig", {
                    validateStatus: () => true, // no error
                    params: {
                        session_token: this.session_token,
                        client: this.client
                    }
                })
                this.suppliers = res.data.suppliers
                // config for filepond0
                this.filepond0_width = res.data.filepond0_width
                this.filepond0_height = res.data.filepond0_height


            } catch (error) {
                console.log(error)
            }

        },

        async refreshBatches() {
            // RefreshBatches (version async/await no callback)
            // as per https://rapidapi.com/guides/axios-async-await
            // check https://stackoverflow.com/questions/72685187/how-to-wrap-axios-function-and-let-parent-to-wait
            try {
                const res = await axios.get("/api/RefreshBatches", {
                    validateStatus: () => true, // no error
                    params: {
                        session_token: this.session_token,
                        client: this.client
                    }

                })
                // set variables & handle info message
                if (this.setupState) {
                    this.infoJobsToValidate = res.data.hierarchy.length > 0
                } else {
                    // Check if new batchId registered
                    var previousIterationIds = []
                    var currentIterationIds = []

                    // Handle prev iteration list of registered batch
                    for (let dayLevel of this.hierarchy) {
                        if ("children" in dayLevel) {
                            for (let batch of dayLevel.children) {
                                previousIterationIds.push(batch.id)
                            }
                        }
                    }

                    // Handle current iteration list of registered batch
                    for (let dayLevel of res.data.hierarchy) {
                        if ("children" in dayLevel) {
                            for (let batch of dayLevel.children) {
                                currentIterationIds.push(batch.id)
                            }
                        }
                    }

                    // True if and only if at least one element of current_iterations_ids nto in prev_iteration_ds
                    this.infoNewJobsToValidate = currentIterationIds.some(elem => !previousIterationIds.includes(elem))
                }

                this.hierarchy = res.data.hierarchy
                this.jobsData = res.data.jobsData


            } catch (error) {
                console.log(error)
            }
        },


        async refreshMails() {
            // RefreshMails (version async/await no callback)
            // as per https://rapidapi.com/guides/axios-async-await
            // check https://stackoverflow.com/questions/72685187/how-to-wrap-axios-function-and-let-parent-to-wait
            try {
                const res = await axios.get("/api/RefreshMails", {
                    validateStatus: () => true, // no error
                    params: {
                        session_token: this.session_token,
                        client: this.client
                    }
                })
                // set variable
                this.mails = res.data.mails
            } catch (error) {
                console.log(error)
            }
        },


        async setup() {

            await this.getUserConfig()
            await this.refreshBatches()
            await this.refreshMails()

            // setupState is False
            this.setupState = false

            // register 1m
            this.schedulerRefreshBatches = setInterval(async () => await this.refreshBatches(), this.refreshFrequency)
            this.schedulerRefreshMails = setInterval(async () => await this.refreshMails(), this.refreshFrequency)
        },


        // send 
        openDialogSendBatchConfirm: function () {

            // before opening dialog card compute max intervals
            this.computeMaxIntervalsBetweenInvoices()
            // open dialog
            this.openSendBatchConfirmDialog = true
        },

        sendBatchPostConfirm: function () {
            // disable buttons to prevent any action during uploading
            this.openSendBatchConfirmDialog = false
            this.disabled = true
            this.enableOverlayImportMain = true

            // Two limits = limitsUsage
            // 1. block size must be strictly inferior to 25e6 Mo
            // 2. docs per block must be strincly inferior to 40. (roughly 40*4 = 160 pages to process per invocation)
            var sizeThreshold = 25e6
            var blockThreshold = 40

            // Instantiate variables
            var formDataContainer = []
            var formDataSize = Infinity // first call will trigger creation of new FormData in container
            var formDataBlocksCount = Infinity // first call will trigger creation of new FormData in container

            // Keep track of nb_blocks total and key.
            var key = 0
            var nb_blocks = 0

            // Get indices where fileDelimiter is true
            var indicesDelimiters = this.filesDelimiters.map((e, i) => e === true ? i : '').filter(String)

            // Start iteration 
            for (let i = 0; i < indicesDelimiters.length; i++) {
                // Get indices
                var ix_start = indicesDelimiters[i]
                var ix_end = i == indicesDelimiters.length - 1 ? this.filesSplitted.length : indicesDelimiters[i + 1]
                // adjust ix_end if more than this.maxInterval elements
                ix_end = ix_end - ix_start > this.maxInterval ? ix_start + this.maxInterval : ix_end

                // block size slice doesn't include Ubound by construction
                var block = this.filesSplitted.slice(ix_start, ix_end)
                var block_size = block.map(f => f.size).reduce((prev, current) => prev + current, 0)
                // generate identifier 
                var block_id = uuid.v4()
                // increment nb_blocks
                nb_blocks += 1

                // Get info of block first file as reference
                var supplier = this.filesStates[block[0].name]
                var supplierInfo = this.suppliers[this.suppliers.map(item => item.model_id).indexOf(supplier)]

                // If limitsUsage are within limits then we can keep the formDatacontainer.
                // Else we create a new one and update limitsUsage.
                if ((formDataSize + block_size >= sizeThreshold) || (formDataBlocksCount + 1 >= blockThreshold)) {
                    key = 0
                    // create new instance in formDataContainer
                    formDataContainer.push({
                        "formData": new FormData(),
                        "parent": {},
                        "supplier": {},
                        "form_recognizer_id": {},
                        "docFormat": {}
                    })
                    // Update limitsUsage
                    formDataSize = 0
                    formDataBlocksCount = 0
                }

                // Get Active formDataContainer
                var ix = formDataContainer.length - 1

                // Register block_id in formDataContainer
                if (!(block_id in formDataContainer[ix]["parent"])) {
                    formDataContainer[ix]["parent"][block_id] = []
                }

                // Update formDataBlocksCount (limitsUsage).
                // 1 doc = 1 block
                formDataBlocksCount += 1

                // Add files to formDataContainer
                for (var f of block) {
                    formDataContainer[ix]["formData"].append(key, f)
                    // update 
                    formDataContainer[ix]["parent"][block_id].push(key)
                    // Update key 
                    key += 1
                    // Update formDataSize (limitsUsage)
                    formDataSize += f.size
                }

                // Update meta
                formDataContainer[ix]["supplier"][block_id] = supplier
                formDataContainer[ix]["form_recognizer_id"][block_id] = supplierInfo.form_recognizer_id
                formDataContainer[ix]["docFormat"][block_id] = supplierInfo.docFormat

            }

            // bqtch Id is created on the fly
            var batch_id
            batch_id = uuid.v4()

            var cfd_config
            var cfd
            var uploadPromises = []

            // Helper function to upload formDataContainer
            const uploadDocument = (cfd, session_token, batch_id) => {
                return axios.post("/api/UploadDocuments", cfd, {
                    params: {
                        session_token: session_token,
                        batchId: batch_id
                    },
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
            };

            // Iterate over formDataContainer and push promises to uploadPromises
            for (let i = 0; i < formDataContainer.length; i++) {
                // current form data
                cfd_config = formDataContainer[i]
                cfd = cfd_config.formData
                // complete form data with processing info
                cfd.append("parent", JSON.stringify(cfd_config.parent))
                cfd.append("supplier", JSON.stringify(cfd_config.supplier))
                cfd.append("form_recognizer_id", JSON.stringify(cfd_config.form_recognizer_id))
                cfd.append("docFormat", JSON.stringify(cfd_config.docFormat))

                // add promise to uploadPromises
                uploadPromises.push(uploadDocument(cfd, this.session_token, batch_id))
            }

            // Execute all uploads and then register batch
            Promise.all(uploadPromises)
                .then(() => {
                    // Register batch 
                    axios.get("/api/RegisterBatch", {
                        params: {
                            session_token: this.session_token,
                            client: this.client,
                            batchId: batch_id,
                            nb_documents: nb_blocks,
                            // datasource is default if has_multiple_datasources is false else it's selected_datasource
                            datasource: this.has_multiple_datasources ? this.selected_datasource : "default",
                        }
                    })
                        .then(() => {
                            if (this.toggle_source == 1) { // that's a mail

                                // Kill execution of setInterval
                                clearInterval(this.schedulerRefreshMails)

                                axios.get("api/DiscardMail", {
                                    params:
                                    {
                                        session_token: this.session_token,
                                        client: this.client,
                                        mailsIDs: this.activeMailsIDs
                                    }
                                })
                                    .then(async () => {
                                        this.resetVariables()
                                        // Refresh mail
                                        await this.refreshMails()
                                        this.schedulerRefreshMails = setInterval(async () => await this.refreshMails(), this.refreshFrequency)
                                        // Successful call
                                        this.enableOverlayImportMain = false
                                        this.successSendJob = true
                                    })
                                    .catch(() => {
                                        // Error in DiscardMail
                                        this.enableOverlayImportMain = false
                                        this.errorUpload = true
                                    })
                            } else { // that's a file
                                this.resetVariables()
                                // Successful call
                                this.enableOverlayImportMain = false
                                this.successSendJob = true
                            }
                        }
                        )
                        .catch(() => {
                            this.resetVariables()
                            // Error in RegisterBatch
                            this.enableOverlayImportMain = false
                            this.errorUpload = true
                        })
                }
                )
                .catch(() => {
                    this.resetVariables()
                    // Error in UploadDocuments
                    this.enableOverlayImportMain = false
                    this.errorUpload = true
                })
        },

        // reset
        openDialogResetBatchConfirm: function () {
            this.openResetBatchConfirmDialog = true
        },

        // handle reset when switching from mail or windows source
        resetVariables: function (hard = true) {

            // unlock selection
            this.selectionLock = false

            if (hard) {
                this.files = []
                this.activeMailsIDs = []
            }

            this.fileInputValidationRules = []

            this.filesSplitted = []
            this.filesClusters = []
            this.filesDelimiters = []
            this.filesStates = []
            this.filesImportPreview = [] // bug when reset or send potential.
            this.nativeSplits = []
            this.validSections = []


            this.batchResolver = false
            this.selectedSectionIndex = 0
            this.searchModel = ""
            this.selectedSupplier = {}


            this.openResetBatchConfirmDialog = false
            this.disabled = true

        },

        // Handle toggle of mails :feat
        toggle: function () {
            this.$nextTick(() => {
                if (this.selectedAllMails) {
                    this.activeMailsIDs = []
                } else {
                    this.activeMailsIDs = this.mails.slice().map(mail => mail.value);
                }
            })
        },

        toggleIcon: function () {
            if (this.selectedAllMails) return "mdi-minus-box"
            if (this.selectedSomeMails) return "mdi-plus-box"
            return "mdi-plus-box"
        },


        forceCloseMailSelect: function () {
            this.$refs.ref_select_mail.blur()
        },


        // handle file Preview
        handlePreviewImportFiles: function (index) {
            this.enableOverlayImportPond = true
            this.filesImportPreview = this.filesClusters[index]
            try {
                // Scroll filepond0 to top 
                var pond = document.getElementById("filepond0")
                var scroller = pond.getElementsByClassName("filepond--list-scroller")[0]
                scroller.scrollTo(0, 0)
            } catch {
                // do nothing
            }
            setTimeout(() => { this.enableOverlayImportPond = false; }, 4000);
        },

        // sync pdf split
        async AsyncSplitterSuiviPdfFiles(file) {

            var individualsPDFS = []
            var cpt = 0

            var pdfBytes = await file.arrayBuffer();
            const pdfDoc = await PDFDocument.load(pdfBytes, { ignoreEncryption: true })
            const numberOfPages = pdfDoc.getPages().length

            for (let j = 0; j < numberOfPages; j++) {
                // Create a new "sub" document
                const subDocument = await PDFDocument.create()
                // copy the page at current index
                const [copiedPage] = await subDocument.copyPages(pdfDoc, [j])
                subDocument.addPage(copiedPage)
                // push to subPDFS
                var pageByte = await subDocument.save()
                var new_file = new File([pageByte], cpt, { type: "application/pdf" })
                // await append
                individualsPDFS[cpt] = new_file
                // increment
                cpt += 1
            }

            this.filesSuiviPreview = individualsPDFS
        },


        // handle incoming batch
        async AsyncSplitterImportPdfFiles(nativeSplits = []) {

            // Instantiate variables
            var individualsPDFS = []
            var cpt = 0
            var computedSplits = []
            // count total pages and size of files. init to 0
            var total_size = 0
            var total_pages = 0

            // Loop over files
            for (let i = 0; i < this.files.length; i++) {

                // Register split
                computedSplits.push(cpt)
                // Load bytes. Can be encrypted or not
                var pdfBytes = await this.files[i].arrayBuffer();
                // Load your PDFDocument
                var pdfDoc = await PDFDocument.load(pdfBytes, { ignoreEncryption: true })

                // Check if encrypted
                if (pdfDoc.isEncrypted) {
                    // try to decrypt it via api/DecryptDocument. Handle error if any
                    try {
                        var formData = new FormData()
                        formData.append("file", this.files[i])
                        var decryptedBytes = await axios.post("/api/DecryptDocument", formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }, responseType: 'arraybuffer'
                        })
                        // construct new file
                        var decryptedFile = new File([decryptedBytes["data"]], "", { type: "application/pdf" })
                        // get decrypted arrayBuffer
                        var decryptedBuffer = await decryptedFile.arrayBuffer();
                        // modify pdfDoc object
                        pdfDoc = await PDFDocument.load(decryptedBuffer, { ignoreEncryption: true })
                        // check if decrypted
                        if (pdfDoc.isEncrypted) {
                            // handle error
                            this.resetVariables()
                            this.errorEncrypted = true
                            this.enableOverlayImportMain = false
                            return null
                        }
                    } catch {
                        // handle error
                        this.resetVariables()
                        this.errorEncrypted = true
                        this.enableOverlayImportMain = false
                        return null
                    }
                }

                // increment total size and total pages
                total_size += this.files[i].size
                total_pages += pdfDoc.getPages().length

                // check if total size is ok and total pages is ok
                if (total_size > this.maxAllowedSize * 1e6) {
                    this.resetVariables()
                    this.enableOverlayImportMain = false
                    // push error into validation rules
                    this.fileInputValidationRules = [false || "La taille totale doit être inférieure à " + this.maxAllowedSize + " Mo."]
                    return null
                }

                if (total_pages > this.maxAllowedNbPages) {
                    this.resetVariables()
                    this.enableOverlayImportMain = false
                    // push error into validation rules
                    this.fileInputValidationRules = [false || "Le nombre total de pages doit être inférieur à " + this.maxAllowedNbPages + "."]
                    return null
                }


                // Process
                const numberOfPages = pdfDoc.getPages().length
                // Loop over pages of file
                for (let j = 0; j < numberOfPages; j++) {
                    // Create a new "sub" document
                    const subDocument = await PDFDocument.create()
                    // copy the page at current index
                    const [copiedPage] = await subDocument.copyPages(pdfDoc, [j])
                    subDocument.addPage(copiedPage)
                    // push to subPDFS
                    var pageByte = await subDocument.save()
                    var file = new File([pageByte], cpt, { type: "application/pdf" })
                    // await append
                    individualsPDFS[cpt] = file
                    // increment
                    cpt += 1
                }
            }

            this.filesSplitted = individualsPDFS
            // If nativeSplits given in the function is not [] then use that else use computed.
            this.nativeSplits = nativeSplits.length > 0 ? nativeSplits : computedSplits
        },


        async ProcessIncomingPDFFiles(nativeSplits = []) {

            this.resetVariables(false)
            this.selectionLock = true // lock selection

            // Check if on:change = deletion of input
            if (this.files.length == 0) {
                return null
            }

            // freeze UI
            this.enableOverlayImportMain = true
            // Split incoming PDFS. n multipage PDFS to m single page PDFS
            await this.AsyncSplitterImportPdfFiles(nativeSplits)

            // Next operation only legit if async splitter has worked.
            if (this.filesSplitted.length > 0) {
                // Get clusters i.e transform original input into chunck of size this.chunckSize or less.
                // validSections => keep track of whether the section has been processed by user
                // Array[File] into Array[Array[File]]
                for (let i = 0; i < this.filesSplitted.length; i += this.chunckSize) {
                    const chunk = this.filesSplitted.slice(i, i + this.chunckSize);
                    // append
                    this.filesClusters[i / this.chunckSize] = chunk
                    this.validSections[i / this.chunckSize] = false
                }

                // Handle case nb of files last filesClusters is below this.maxInterval only if 
                // there's at least this.chunckSize files in the filesSplitted
                if (this.filesSplitted.length > this.chunckSize) {
                    var last_ix = this.filesClusters.length - 1
                    if (this.filesClusters[last_ix].length < this.maxInterval) {
                        // Delete last_ix
                        this.validSections.pop()
                        var tmpFiles = this.filesClusters.pop()
                        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_syntax
                        // last-ix - 1 since the last item array has been popped.
                        this.filesClusters[last_ix - 1].push(...tmpFiles)

                    }

                }

                //this.filesImportPreview = this.filesClusters[0]
                this.handlePreviewImportFiles(0)

            }

            // Unfreeze UI.
            this.enableOverlayImportMain = false


        },


        handleFileState: function (e) {
            // Handle FileState i.e when user clicks on a file.

            // Instantiate variables
            var itemsCollection
            var item
            var fileWrapper
            var file
            var fileInfo
            var fileInfoMain

            var sectionRow

            // Get pond0
            var pond = document.getElementById("filepond0")

            // Case batch resolver is True => batch of documents for same supplier.
            if (this.batchResolver) {

                // Generate filesDelimiters from nativeSplits
                for (let i = 0; i < this.filesSplitted.length; i++) {
                    this.filesDelimiters[i] = this.nativeSplits.indexOf(i) != -1
                }

                // Check if sections have already been validated or not. 
                if (this.validSections.every(item => item === false)) {
                    // => Case not validated yet
                    // Update filesStates with the supplier at index in this.nativeSplits
                    for (let i = 0; i < this.filesSplitted.length; i++) {
                        this.filesStates[i] = this.nativeSplits.indexOf(i) != -1 ? this.selectedSupplier.model_id : "Idle"
                    }

                    // Update validSections. If batch then by construction all sections are processed
                    for (let j = 0; j < this.filesClusters.length; j++) {
                        this.validSections[j] = true
                    }

                    // Disabled = False => Data can be sent to backend
                    this.disabled = false

                    // Handle UI for items in preview

                    itemsCollection = pond.getElementsByClassName("filepond--item")
                    for (let i = 0; i < this.filesImportPreview.length; i++) {
                        // Navigate to Element 
                        item = itemsCollection[i]
                        fileWrapper = item.getElementsByClassName("filepond--file-wrapper")[0]
                        file = fileWrapper.getElementsByClassName("filepond--file")[0]
                        fileInfo = file.getElementsByClassName("filepond--file-info")[0]
                        fileInfoMain = fileInfo.getElementsByClassName("filepond--file-info-main")[0]

                        if (!(this.nativeSplits.indexOf(i + this.chunckSize * this.selectedSectionIndex) == -1)) {
                            // In case file index of file in preview is a delimiter then attribute becomes set
                            // And supplier becomes this.selectedSupplier (computed property)
                            item.setAttribute("data-filepond-item-state", "set")
                            fileInfoMain.textContent = this.selectedSupplier.alias
                        }
                    }


                    // Icon becomes green
                    for (let i = 0; i < this.filesClusters.length; i++) {
                        sectionRow = document.getElementById("Icon-" + i)
                        sectionRow.setAttribute("class", "v-icon notranslate mdi mdi-segment theme--light green--text")
                    }

                } else {
                    // => Case has alreayd been validated yet
                    if (Object.values(this.filesStates).indexOf(this.selectedSupplier.model_id) == -1) {
                        // Case that's a change of supplier. Not a call for reset.

                        // Update filesStates with the supplier at index in this.nativeSplits
                        for (let i = 0; i < this.filesSplitted.length; i++) {
                            this.filesStates[i] = this.nativeSplits.indexOf(i) != -1 ? this.selectedSupplier.model_id : "Idle"
                        }

                        // Handle UI for items in preview
                        itemsCollection = pond.getElementsByClassName("filepond--item")
                        for (let i = 0; i < this.filesImportPreview.length; i++) {
                            // Navigate to Element 
                            item = itemsCollection[i]
                            fileWrapper = item.getElementsByClassName("filepond--file-wrapper")[0]
                            file = fileWrapper.getElementsByClassName("filepond--file")[0]
                            fileInfo = file.getElementsByClassName("filepond--file-info")[0]
                            fileInfoMain = fileInfo.getElementsByClassName("filepond--file-info-main")[0]

                            if (!(this.nativeSplits.indexOf(i) == -1)) {
                                // Supplier becomes this.selectedSupplier (computed property)
                                fileInfoMain.textContent = this.selectedSupplier.alias
                            }
                        }
                    } else {
                        // Case that's a call for reset.

                        // Reset filesStates
                        for (let i = 0; i < this.filesSplitted.length; i++) {
                            var f = this.filesSplitted[i]
                            this.filesStates[f.name] = "Idle"
                        }

                        // Reset validSections
                        for (let j = 0; j < this.filesClusters.length; j++) {
                            this.validSections[j] = false
                        }

                        // Handle UI for items in preview
                        itemsCollection = pond.getElementsByClassName("filepond--item")
                        for (let i = 0; i < this.filesImportPreview.length; i++) {
                            // Navigate to Element 
                            item = itemsCollection[i]
                            fileWrapper = item.getElementsByClassName("filepond--file-wrapper")[0]
                            file = fileWrapper.getElementsByClassName("filepond--file")[0]
                            fileInfo = file.getElementsByClassName("filepond--file-info")[0]
                            fileInfoMain = fileInfo.getElementsByClassName("filepond--file-info-main")[0]
                            // Supplier becomes "" and state => Idle
                            item.setAttribute("data-filepond-item-state", "idle")
                            fileInfoMain.textContent = ""
                        }

                        // Icon becomes grey and disabled
                        for (let i = 0; i < this.filesClusters.length; i++) {
                            sectionRow = document.getElementById("Icon-" + i)
                            sectionRow.setAttribute("class", "v-icon notranslate mdi mdi-segment theme--light")
                        }
                        // freeze send ui
                        this.disabled = true

                    }

                }

            } else {
                // Case batch resolver is False => one document or multi documents of different suppliers 
                // Requires manual processing

                // Find index of element e in itemsCollection
                itemsCollection = Array.prototype.slice.call(pond.getElementsByClassName("filepond--item"))
                var itemsID = itemsCollection.map(item => item.id)
                var indexID = itemsID.indexOf("filepond--item-" + e.id)

                // Navigate to Element and gather informations
                item = document.getElementById("filepond--item-" + e.id)
                fileWrapper = item.getElementsByClassName("filepond--file-wrapper")[0]
                file = fileWrapper.getElementsByClassName("filepond--file")[0]
                fileInfo = file.getElementsByClassName("filepond--file-info")[0]
                fileInfoMain = fileInfo.getElementsByClassName("filepond--file-info-main")[0]

                // Determine current state for element
                var state = item.getAttribute("data-filepond-item-state")

                if (state != "set") {
                    // Case state is idle then on-click it becomes set
                    item.setAttribute("data-filepond-item-state", "set")
                    fileInfoMain.textContent = this.selectedSupplier.alias
                    // Update state & Delimiter
                    this.filesStates[e.filename] = this.selectedSupplier.model_id
                    this.filesDelimiters[this.selectedSectionIndex * this.chunckSize + indexID] = true

                } else {
                    // Case state is set.
                    if (fileInfoMain.textContent == this.selectedSupplier.alias) {
                        // Case supplier is the same then => calls for reset
                        item.setAttribute("data-filepond-item-state", "idle")
                        fileInfoMain.textContent = ""
                        this.filesDelimiters[this.selectedSectionIndex * this.chunckSize + indexID] = false
                        this.filesStates[e.filename] = "Idle"
                    } else {
                        // Case supplier is different then => update.
                        fileInfoMain.textContent = this.selectedSupplier.alias
                        this.filesStates[e.filename] = this.selectedSupplier.model_id
                    }
                }

                // Determine if section is valid
                var offset = this.chunckSize * this.selectedSectionIndex
                var LBound = offset + 0
                var UBound = offset + this.filesClusters[this.selectedSectionIndex].length
                // Section is valid if at least one delimiter in available in slice
                var validSection = this.filesDelimiters.slice(LBound, UBound).some(item => item === true)

                // modify UI
                sectionRow = document.getElementById("Icon-" + this.selectedSectionIndex)
                if (validSection) {
                    // If section is valid icon becomes green 
                    sectionRow.setAttribute("class", "v-icon notranslate mdi mdi-segment theme--light green--text")
                    this.validSections[this.selectedSectionIndex] = true
                } else {
                    // If section is not valid icon becomes grey 
                    sectionRow.setAttribute("class", "v-icon notranslate mdi mdi-segment theme--light")
                    this.validSections[this.selectedSectionIndex] = false
                }

                // Determine if send button is disabled or not based on validSections
                // condition is all true except last or all true 
                if (this.validSections.length == 1) {
                    this.disabled = !(this.validSections[0] === true)
                } else if (this.validSections.length > 1) {
                    const allTrue = this.validSections.every(item => item === true)
                    const allTrueExceptLast = this.validSections.slice(0, -1).every(item => item === true) && this.validSections[this.validSections.length - 1] === false
                    this.disabled = !(allTrue || allTrueExceptLast)
                } else {
                    this.disabled = true
                }

            }

        },


        handleFileAdd: function (error, e) {
            // Handle the event : File has been added to the GUI.
            // Determine what's the state of the file
            var state = this.filesStates[e.filename] ?? "Idle"
            // Handle UI for item
            // Navigate to element
            var item = document.getElementById("filepond--item-" + e.id)
            var fileWrapper = item.getElementsByClassName("filepond--file-wrapper")[0]
            var file = fileWrapper.getElementsByClassName("filepond--file")[0]
            var fileInfo = file.getElementsByClassName("filepond--file-info")[0]
            var fileInfoMain = fileInfo.getElementsByClassName("filepond--file-info-main")[0]

            if (state != "Idle") {
                // Case file is a delimiter
                item.setAttribute("data-filepond-item-state", "set")
                fileInfoMain.textContent = this.suppliers[this.suppliers.map(item => item.model_id).indexOf(state)].alias

            } else {
                // Case it's not a delimiter
                fileInfoMain.textContent = ""
            }

        },


        handleBatchContextSwitcher: function () {

            // Handle click on switch toggle.
            this.batchResolver = !this.batchResolver
            // When toggle is switched then everything is reset
            // Case that's a call for reset.
            var sectionRow

            // Get filepond0
            var pond = document.getElementById("filepond0")

            // Reset 
            this.filesDelimiters = []
            this.filesStates = []

            // Reset validSections
            for (let j = 0; j < this.filesClusters.length; j++) {
                this.validSections[j] = false
            }

            // Handle UI for items in preview
            var itemsCollection = pond.getElementsByClassName("filepond--item")
            for (let i = 0; i < this.filesImportPreview.length; i++) {
                // Navigate to Element 
                var item = itemsCollection[i]
                var fileWrapper = item.getElementsByClassName("filepond--file-wrapper")[0]
                var file = fileWrapper.getElementsByClassName("filepond--file")[0]
                var fileInfo = file.getElementsByClassName("filepond--file-info")[0]
                var fileInfoMain = fileInfo.getElementsByClassName("filepond--file-info-main")[0]
                // Supplier becomes "" and state => Idle
                item.setAttribute("data-filepond-item-state", "idle")
                fileInfoMain.textContent = ""
            }

            // Icon becomes grey and disabled
            for (let i = 0; i < this.filesClusters.length; i++) {
                sectionRow = document.getElementById("Icon-" + i)
                sectionRow.setAttribute("class", "v-icon notranslate mdi mdi-segment theme--light")
            }

            this.disabled = true

        },



        computeMaxIntervalsBetweenInvoices: function () {
            // Compute max interval between invoices
            // reset fileIntervals
            this.alertIntervals = []
            // first delimiter
            var _alertIntervals = []
            var interval
            var section

            var indicesDelimiters = []
            // Determine indices of delimiters
            for (let i = 0; i < this.filesDelimiters.length; i++) {
                if (this.filesDelimiters[i] === true) {
                    indicesDelimiters.push(i)
                }
            }

            // register intervals in error
            for (let i = 0; i < indicesDelimiters.length - 1; i++) {
                interval = indicesDelimiters[i + 1] - indicesDelimiters[i] // nb pages 
                if (interval >= this.maxInterval) {
                    // section
                    section = Math.floor(indicesDelimiters[i] / this.chunckSize) + 1
                    _alertIntervals.push({ "section": section, "start": indicesDelimiters[i] + 1, "end": indicesDelimiters[i + 1] })
                }
            }

            // handle last interval if required
            if (indicesDelimiters.indexOf(this.filesSplitted.length - 1) == -1) {
                var i = indicesDelimiters.length - 1
                interval = this.filesSplitted.length - indicesDelimiters[i]
                if (interval >= this.maxInterval) {
                    // section
                    section = Math.floor(indicesDelimiters[i] / this.chunckSize) + 1
                    _alertIntervals.push({ "section": section, "start": indicesDelimiters[i] + 1, "end": this.filesSplitted.length })
                }

            }

            this.alertIntervals = _alertIntervals

        },

        ////////////////////// PART OF NEW DEV
        // Get Active Job
        getActiveJob() {

            if (this.activeBatchId.length == 0) {
                this.activeJob = {}
            } else {
                // Reprocess active job to take into account cached_operations
                var job = this.jobsData[this.activeBatchId]
                var current
                var activeDocIndex
                // Check if there's cached operations
                if (!(this.activeBatchId in this.cached_operations)) {
                    this.activeJob = job
                } else {
                    for (var document_id of Object.keys(this.cached_operations[this.activeBatchId])) {
                        current = this.cached_operations[this.activeBatchId][document_id]
                        activeDocIndex = this.jobsData[this.activeBatchId].data[current.model].map(i => i.DOCUMENT_ID).indexOf(document_id)
                        // Set DELETE = True
                        job.data[current.model][activeDocIndex].DELETE = current.delete
                    }
                    this.activeJob = job
                }

            }
        },


        // Alias from modelId
        getModelAlias: function (model) {
            return this.suppliers[this.suppliers.map(item => item.model_id).indexOf(model)].alias
        },


        // Register user operation 
        cacheOperation: function (document_id, model) {

            var current

            if (this.activeBatchId in this.cached_operations) {
                if (document_id in this.cached_operations[this.activeBatchId]) {
                    // Update current value
                    current = this.cached_operations[this.activeBatchId][document_id]
                    this.cached_operations[this.activeBatchId][document_id].delete = !(current.delete)
                } else {
                    this.cached_operations[this.activeBatchId][document_id] = {
                        "model": model,
                        "delete": true
                    }
                }
            }

            else {
                this.cached_operations[this.activeBatchId] = {
                    [document_id]: {
                        "model": model,
                        "delete": true
                    }
                }
            }

        },



        // Clean cache for supplier
        cleanCacheOperations: function (type, model = null) {
            var document_id

            if (type == "job") {
                // Set all to delete = false
                if (this.activeBatchId in this.cached_operations) {
                    for (document_id of Object.keys(this.cached_operations[this.activeBatchId])) {
                        this.cached_operations[this.activeBatchId][document_id].delete = false
                    }
                }

            } else {
                // get document_id associated with model_id
                if (this.activeBatchId in this.cached_operations) {
                    for (document_id of Object.keys(this.cached_operations[this.activeBatchId])) {
                        if (this.cached_operations[this.activeBatchId][document_id].model == model) {
                            this.cached_operations[this.activeBatchId][document_id].delete = false
                        }
                    }
                }
            }

            // Reload active job 
            this.getActiveJob()
        },


        downloadDocuments(document_ids, container) {

            return new Promise((resolve, reject) => {
                axios.get("/api/DownloadDocuments", {
                    params: {
                        session_token: this.session_token,
                        documents: document_ids,
                        container: container
                    },
                    responseType: 'arraybuffer'
                })
                    .then((response) => resolve(response.data))
                    .catch((error) => reject(error))
            })
        },

        downloadPreviewDocument(document_id) {
            // Download preview document for filepond results preview
            this.enableOverlaySuiviPond = true
            var response = this.downloadDocuments(document_id + ".pdf", "input")
            response
                .then(data => {
                    var file = new File([data], "", { type: "application/pdf" })
                    this.AsyncSplitterSuiviPdfFiles(file)
                })
                .catch(() => this.errorDownload = true)
                .finally(() => {
                    this.enableOverlaySuiviPond = false
                    try {
                        // Scroll filepond1 to top 
                        var pond = document.getElementById("filepond1")
                        var scroller = pond.getElementsByClassName("filepond--list-scroller")[0]
                        scroller.scrollTo(0, 0)
                    } catch {
                        // do nothing
                    }
                })

        },


        downloadIncomingMailDocuments() {
            // close v-select
            this.forceCloseMailSelect()

            // Download mails attachments for filepond import
            this.resetVariables(false)
            // Reset this.files whatever.
            this.files = []

            // Check if on:change = deletion of input
            if (this.activeMailsIDs == []) {
                return null
            }

            // Handle ui overlay
            this.enableOverlayImportMain = true

            // Load documents
            axios.get("api/GetMailMeta", {
                params: {
                    session_token: this.session_token,
                    mailsIDs: this.activeMailsIDs,
                    client: this.client
                }
            })
                .then(async (mailMeta) => {
                    // Get nativeSplits
                    var _nativeSplits = mailMeta.data["nativeSplits"]
                    var _files = mailMeta.data["files"]
                    // Loop over attachments batch (max 15Mo cf api/DownloadDocuments)
                    for (var key in _files) {
                        // DownloadDocuments
                        var array_buffer = await this.downloadDocuments(_files[key], "mails")
                        // Push merged file to this.files
                        this.files.push(new File([array_buffer], "", { type: "application/pdf" }))
                    }
                    // now process files
                    this.ProcessIncomingPDFFiles(_nativeSplits)
                }
                )
                .catch(() => {
                    this.resetVariables() // hard = true. Mail is corrupted or error download
                    this.errorDownload = true
                })
                .finally(() => // workflow is completed we can start a new one.
                    this.enableOverlayImportMain = false)
        },


        convertDateHelper: function (date) {

            try {
                var day = date.slice(0, 2)
                var month = date.slice(2, 4)
                var year = date.slice(4, 8)
                var new_date = day + "/" + month + "/" + year
                // check if date is valid
                var dt = new Date(year + "-" + month + "-" + day)
                var valid = dt instanceof Date && !isNaN(dt)

                return valid ? new_date : date

            } catch (err) {
                return date
            }
        },


        removeJob() {

            // Kill execution of setInterval
            clearInterval(this.schedulerRefreshBatches)

            var fd = new FormData()
            fd.append("expected_status", 99)

            // Handle ui overlay
            this.enableOverlaySuiviMain = true

            // Set job status to 99
            axios.post("/api/RegisterJobPostProcessing", fd, {
                validateStatus: () => true, // no error
                params: {
                    session_token: this.session_token,
                    client: this.client,
                    batchId: this.activeBatchId,
                    nb_documents: this.activeJob.nb_documents
                }
            })
                .then(async () => {
                    // Remove batch from cache_operations for active job
                    delete this.cached_operations[this.activeBatchId]
                    this.activeBatchId = []
                    this.activeJob = {}
                    this.filesSuiviPreview = []
                    // Refresh batches
                    await this.refreshBatches()
                    // Restart refresh batches
                    this.schedulerRefreshBatches = setInterval(async () => await this.refreshBatches(), this.refreshFrequency)
                    // success snackbar
                    this.successDropResults = true

                })
                .catch((error) => { console.log(error) })
                .finally(() => { this.enableOverlaySuiviMain = false })


        },

        sendJob() {

            // Kill execution of setInterval
            clearInterval(this.schedulerRefreshBatches)

            // RegisterJobPostProcessing
            var fd = new FormData()
            fd.append("expected_status", 2)
            if (this.activeBatchId in this.cached_operations) {
                // In case restrictions.
                fd.append("restrictions", JSON.stringify(this.cached_operations[this.activeBatchId]))
            }

            // Handle ui overlay
            this.enableOverlaySuiviMain = true

            // Set job status to 2
            axios.post("/api/RegisterJobPostProcessing", fd, {
                validateStatus: () => true, // no error
                params: {
                    session_token: this.session_token,
                    client: this.client,
                    batchId: this.activeBatchId,
                    nb_documents: this.activeJob.nb_documents
                }
            })
                .then(async () => {
                    // Remove batch from cache_operations for active job
                    delete this.cached_operations[this.activeBatchId]
                    this.activeBatchId = []
                    this.activeJob = {}
                    this.filesSuiviPreview = []
                    // Refresh batches
                    await this.refreshBatches()
                    // Restart refresh batches
                    this.schedulerRefreshBatches = setInterval(async () => await this.refreshBatches(), this.refreshFrequency)
                    // success snackbar
                    this.successSendResults = true
                })
                .catch((error) => { console.log(error) })
                .finally(() => { this.enableOverlaySuiviMain = false })

        },



    },
    components: {
        FilePond,
        ValidationObserver,
        ValidationProvider
    },

    async created() {
        await this.setup()
    },

    data: () => ({

        // setup selected datasource defualt is undefined
        selected_datasource: undefined,

        // setup State
        setupState: true,

        // refresh
        refreshFrequency: 10 * 1000, // in ms 

        // running thread refreshBatchesid
        schedulerRefreshBatches: null,

        // init variables
        tab: "import",
        toggle_source: 0,

        searchModel: "",
        suppliers: [],

        // mails
        mails: [],
        activeMailsIDs: [], // :feat

        // files
        files: [],
        filesSplitted: [],
        filesClusters: [],
        filesDelimiters: [],
        filesStates: {},
        chunckSize: 30,
        maxAllowedSize: 100,
        maxAllowedNbPages: 800,
        totalPages: 0,

        // other
        nativeSplits: [],

        // preview
        filesImportPreview: [],

        // batch
        batchResolver: false,

        // file-input validation rules
        fileInputValidationRules: [],

        // validation
        validSections: [],

        // dialog confirm
        openSendBatchConfirmDialog: false,
        openResetBatchConfirmDialog: false,

        // disable 
        disabled: true,

        // selection lock
        selectionLock: false,

        // overlay
        enableOverlayImportMain: false,
        enableOverlayImportPond: false,
        enableOverlaySuiviMain: false,
        enableOverlaySuiviPond: false,

        // user selection
        selectedSectionIndex: 0,
        selectedSupplier: {},

        // user selection query new model
        openUserQueryNewModelDialog: false,
        userQueryNewModelName: '',
        userQueryNewModelFiles: null,
        userQueryNewModelContractNumber: '',
        userQueryNewModelInvoiceLabel: '',

        // error
        successSendModel: false,
        infoJobsToValidate: false,
        infoNewJobsToValidate: false,
        successSendJob: false,
        successSendResults: false,
        successDropResults: false,
        errorEncrypted: false,
        errorUpload: false,
        errorDownload: false,
        errorModelUpload: false,
        errorModelAlreadyExists: false,
        timeout_in_ms: 5000,

        // maxInterval between invoice headers
        alertIntervals: [],
        maxInterval: 8,

        // Tab SUIVI

        headers: [
            { text: 'preview', value: 'preview', sortable: false },
            { text: 'DELETE', value: 'DELETE', sortable: false },
            { text: "Contrat", value: "NO_CONTRAT", align: "right", sortable: false },
            { text: "Libellé", value: "LIBELLE_FAC", align: "right", sortable: false },
            { text: 'Date', value: 'DATE_FAC', align: "right", sortable: false },
            { text: 'HT(€)', value: 'MONTANT_HT', align: "right", sortable: false },
            { text: 'TVA(€)', value: 'MONTANT_TVA', align: "right", sortable: false },
            { text: 'TTC(€)', value: 'MONTANT_TTC', align: "right", sortable: false },
            { text: 'Score', value: 'CONFIDENCE', align: "right", sortable: false },
        ],


        // suivi 
        filesSuiviPreview: [],
        panel: [],

        // Batches results
        hierarchy: [],
        jobsData: {},
        activeJob: {},
        activeBatchId: [],
        cached_operations: {},

        // Dynamic Binding Filepond0. Default
        filepond0_width: "33%",
        filepond0_height: 400

    }),

    computed: {

        selectedAllMails() {
            return this.activeMailsIDs.length === this.mails.length
        }, // :feat

        selectedSomeMails() {
            return this.activeMailsIDs.length > 0 && !this.selectedAllMails
        }, // :feat

        has_multiple_datasources() {
            // if prop datasource is not Null or undefined or empty return splitted datasource else return [this.company]
            return this.databases != null && this.databases != undefined && this.databases.length > 0 ? this.databases.split(",").length > 1 : false
        },

        multiple_datasources() {
            // if prop datasource is not Null or undefined or empty return splitted datasource else return [this.company]
            return this.databases != null && this.databases != undefined && this.databases.length > 0 ? this.databases.split(",") : ["default"]
        },

        filteredSuppliers() {
            // case insensitive search and remove accents

            // remove accents 
            function removeAccents(str) {
                return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            }

            // case searchModel empty => return all suppliers available
            if (this.searchModel == null) {
                return this.suppliers
            } else {
                var tmp = this.suppliers.filter(item => removeAccents(item.alias.toLowerCase()).includes(removeAccents(this.searchModel.toLowerCase())))
                // case no supplier match the search query. Return générique only
                if (tmp.length == 0) {
                    return this.suppliers.filter(item => item.alias == "Générique")
                } else {
                    // return suppliers subset that match the filter.
                    return tmp
                }
            }
        }

    }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Open+Sans');

.sticky {
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: white;
    /* Set background to match the dropdown for a seamless look */
}

/* Global styles or within your component without scoped */
.v-menu__content.theme--light.menuable__content__active {
    max-height: 400px !important;
    /* Using !important to ensure override */
    overflow-y: auto;
    /* Ensures that content can scroll if it exceeds the max height */
}

/* [START OF VUETIFY CUSTOM]*/
.v-application {
    font-family: "Open Sans";
}

.v-main__wrap {
    background-color: #fff;
}

.v-card {
    /*min-height: 99%;*/
    max-height: 99.9%;
}

.v-data-table>.v-data-table__wrapper>table>tbody>tr>td,
.v-data-table>.v-data-table__wrapper>table>thead>tr>td,
.v-data-table>.v-data-table__wrapper>table>tfoot>tr>td {
    font-size: 0.9em;
}

.v-expansion-panel-header {
    padding: 1px 24px;
}

.v-input--selection-controls {
    margin-top: 0px;
}

.v-input {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.375rem;
    letter-spacing: 0.0071428571em;
}

.v-label {
    font-size: 13.5px
}

.theme--light.v-input {
    color: rgba(0, 0, 0, 0.6);
}

/* [END OF VUETIFY CUSTOM]*/
/* [START OF FILEPOND CUSTOM]*/


.filepond--root {
    /*max-height: 75em:*/
    font-family: 'Open Sans';
    height: calc(100vh - 193px)
        /* in em ref @ 16px */
        /*max-height: 10em;*/
}

.filepond--panel-root {
    background-color: transparent;
}

.filepond--root .filepond--drop-label {
    min-height: 1em;
    max-height: 1em;
}


.card-filepond0 .filepond--item {
    width: calc(v-bind('filepond0_width') - 0.5em);
}

.card-filepond1 .filepond--item {
    width: calc(100%-0.5em);
}

.filepond--pdf-preview-wrapper:before {
    content: ' ';
    position: absolute;
    width: 100%;
    height: 100%;
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 0%);
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 0%);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 0%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#00000000', GradientType=0);
    z-index: 3;
}

/* Override css */

[data-filepond-item-state='set'] .filepond--pdf-preview-wrapper:before {

    background: -moz-linear-gradient(top, rgb(221, 130, 11) 0%, rgba(202, 117, 19, 0.233) 100%);
    background: -webkit-linear-gradient(top, rgb(221, 130, 11) 0%, rgba(202, 117, 19, 0.233) 100%);
    background: linear-gradient(to bottom, rgb(221, 130, 11) 0%, rgba(202, 117, 19, 0.233) 100%);

}

[data-filepond-item-state='idle'] .filepond--item-panel {
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 0%);
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 0%);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 0%);
}


/* [END OF FILEPOND CUSTOM]*/
/* [START OF SKELETON CUSTOM]*/

.skeleton-card-filepond.v-card.v-sheet.theme--light {
    background: linear-gradient(60deg, rgba(84, 58, 183, 1) 0%, rgba(0, 172, 193, 1) 100%)yellowgreen;
    height: calc(100vh - 193px);
}


.card-filepond0.v-card.v-sheet.theme--light {
    background: linear-gradient(60deg, rgba(84, 58, 183, 1) 0%, rgba(0, 172, 193, 1) 100%)yellowgreen;
    height: calc(100vh - 193px);
}

.card-filepond1.v-card.v-sheet.theme--light {
    background: linear-gradient(60deg, rgba(84, 58, 183, 1) 0%, rgba(0, 172, 193, 1) 100%)yellowgreen;
    height: calc(100vh - 193px);
}

/* [END OF SKELETON CUSTOM]*/
/* [START OF WAVES ANIMATION]*/

.flex {
    /*Flexbox for containers*/
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.waves {
    position: relative;
    width: 100%;
    height: 15vh;
    margin-bottom: -7px;
    /*Fix for safari gap*/
    min-height: 100px;
    max-height: 150px;
}

/* Animation */
.parallax>use {
    animation: move-forever 25s cubic-bezier(.55, .5, .45, .5) infinite;
}

.parallax>use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 7s;
}

.parallax>use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 10s;
}

.parallax>use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 13s;
}

.parallax>use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 20s;
}

@keyframes move-forever {
    0% {
        transform: translate3d(-90px, 0, 0);
    }

    100% {
        transform: translate3d(85px, 0, 0);
    }
}

/* [END OF WAVES ANIMATION]*/

/* [START OF METRONOME ANIMATION]*/
@keyframes metronome-example {
    from {
        transform: scale(.5);
    }

    to {
        transform: scale(1);
    }
}

.v-avatar--metronome {
    animation-name: metronome-example;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

/* [END OF METRONOME ANIMATION]*/

/* [START OF SCROLLBAR CUSTOM]*/
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #e8e8e800;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #6969693d;
}

/* [END OF SCROLLBAR CUSTOM]*/
</style>