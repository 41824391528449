<template>
    <v-app>
        <v-app-bar app color="#273540" dark>
            <v-img class="mx-2" :src="require(`../assets/billinsight_logo.svg`)" max-height="150" max-width="150" contain>
            </v-img>
        </v-app-bar>
        <v-main class="readme">
            <!-- This is code for input valid check-->
            <v-container fluid class="ma-0 pa-0">
                <v-img :src="require(`../assets/flowchart.svg`)" :max-height="this.windowHeight" contain>
                </v-img>
            </v-container>
        </v-main>
        <v-footer :padless="true">
            <v-card flat tile width="100%" class="text-center" color="#273540" dark>

                <v-card-text class="white--text"><a href="/files/CGU_072023.pdf" download
                        style="color: #FFFFFF; text-decoration: none; font-size:12px;">CGU</a> |<a
                        href=" /files/MENTIONS_LEGALES_072023.pdf" download
                        style="color: #FFFFFF; text-decoration: none; font-size:12px;">Mentions Légales</a> |
                    <a href="https://azure.microsoft.com/fr-fr/explore/trusted-cloud/privacy/" target="_blank"
                        style="color: #FFFFFF; text-decoration: none; font-size:12px;">RGPD</a> — <strong
                        color-text="red">Billinsight</strong>
                </v-card-text>
                <v-divider></v-divider>
            </v-card>
        </v-footer>
    </v-app>
</template>

<script>
export default {
    data() {
        return {
            windowHeight: window.innerHeight,
        }
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        });
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        onResize() {
            this.windowHeight = window.innerHeight
        }
    }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Open+Sans');

.v-application {
    font-family: "Open Sans";
}

.readme .v-main__wrap {
    background: linear-gradient(60deg, rgba(84, 58, 183, 1) 0%, rgba(0, 172, 193, 1) 100%)yellowgreen
}
</style>